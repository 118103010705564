import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios from 'axios'
import Cookie from 'mobx-cookie'

const API_URL = process.env.REACT_APP_API_URL

export default class Store {

    cookie = new Cookie('token')

    user = {
        roles: [],
        email: '',
        isActivated: false,
        token: ''
    }

    accounts = []

    count = 0

    isAdmin = false
    isAuth = false
    isLoading = false;
    admin = false;
    messages = {
        register: {
            error: {
                message: null
            },
            success: {
                message: null
            }
        },
        login: {
            error: {
                message: null
            },
            success: {
                message: null
            }
        }
    }

    constructor() {
        makeAutoObservable(this);
        this.checkAuth()
        this.checkAdmin()
    }

    setAccounts(data) {
        this.accounts = data
    }

    setCount(count){
        this.count = count
    }

    setToken(token) {
        this.cookie.set(token)
    }

    setAuth(bool) {
        this.isAuth = bool
    }

    setUser(user) {
        this.user = user
    }

    setLoading(bool) {
        this.isLoading = bool;
    }

    setAdmin(bool) {
        this.admin = bool
    }

    setMessages(data) {
        this.messages = data
    }

    setIsAdmin(bool){
        this.isAdmin = bool
    }

    async login(email, password) {
        try {
            const response = await AuthService.login(email, password)
            localStorage.setItem('token', response?.data?.token)
            this.setToken(response?.data?.token, { expires: 365 })
            this.setAuth(true);
            this.setUser(response?.data);
            this.setIsAdmin(response?.data?.roles[0] == "ADMIN" ? true : false)

        } catch (e) {
            const messages = {
                login: {
                    error: {
                        message: e.response?.data?.message
                    },
                    success: {
                        message: null
                    }
                },
                ...this.messages.register
            }
            this.setMessages(messages)

        }
    }

    async registration(email, password) {
        // this.setLoading(true)
        try {
            const response = await AuthService.registration(email, password);
            console.log(response.data?.message)
            const messages = {
                register: {
                    error: {
                        message: null
                    },
                    success: {
                        message: response?.data?.message
                    }
                },
                ...this.messages.login
            }
            this.setMessages(messages)
            // localStorage.setItem('token', response.data.token);
            // this.setAuth(true);
            // this.setUser(response.data.user);
        } catch (e) {
            console.log(e.response?.data?.message);
            const messages = {
                register: {
                    error: {
                        message: e.response?.data?.message
                    },
                    success: {
                        message: null
                    }
                },
                ...this.messages.login
            }
            this.setMessages(messages)
        }
    }

    async checkAuth() {
        this.setLoading(true);
        try {
            if (this.cookie.value) {
                const response = await AuthService.checkAuth(this.cookie.value)
                if (response.data?.token) {
                    localStorage.setItem('token', response.data.token);
                    this.setAuth(true);
                    this.setUser(response.data);
                    this.setIsAdmin(response?.data?.roles[0] == "ADMIN" ? true : false)
                    this.setLoading(false);
                }
            }

        } catch (e) {
            console.log("THIS", e.response?.data?.message);
        } finally {
            this.setLoading(false);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout()
            localStorage.removeItem('token');
            this.cookie.remove()
            this.setAuth(false);
            this.setUser({
                roles: [],
                email: '',
                isActivated: false
            });
        } catch (e) {
            console.log(e.response?.data?.message);
        }
    }

    async checkAdmin() {
        try {
            const response = await AuthService.checkAdmin()
            this.setAdmin(response?.data?.admin)
        } catch (e) {
            console.log(e.response);
        }
    }

    async recover_password_email(email) {
        try {
            const response = await AuthService.recover_password_email(email);
            const messages = {
                login: {
                    error: {
                        message: null
                    },
                    success: {
                        message: response?.data?.message
                    }
                },
                ...this.messages.login
            }
            this.setMessages(messages)
        } catch (e) {
            const messages = {
                login: {
                    error: {
                        message: e.response?.data?.message
                    },
                    success: {
                        message: null
                    }
                },
                ...this.messages.register
            }
            this.setMessages(messages)
        }
    }

    async recover_password(password, link) {
        try {
            const response = await AuthService.recover_password(password, link);
            const messages = {
                login: {
                    error: {
                        message: null
                    },
                    success: {
                        message: response?.data?.message
                    }
                },
                ...this.messages.login
            }
            this.setMessages(messages)
        } catch (e) {
            const messages = {
                login: {
                    error: {
                        message: e.response?.data?.message
                    },
                    success: {
                        message: null
                    }
                },
                ...this.messages.register
            }
            this.setMessages(messages)
        }
    }

    async getAllUsers(){
        try {
            const response = await AuthService.getAllUsers()
            console.log(response?.data)
        } catch (e) {
            console.log(e.response);
        }
    }

}