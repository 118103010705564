import React from "react"
import { Outlet } from "react-router-dom"
import { Container, Flex, Button, Box } from '@chakra-ui/react'
import { Context } from "."
import { observer } from "mobx-react-lite";

const Layout = () => {

    const { store } = React.useContext(Context)

    return (
        <Container minW={"full"} display={"flex"} flexDirection={"column"} h={"100%"}>
            <Flex w={"100%"} justify={"space-between"} alignItems={"center"} borderBottom={"1px"} borderColor="teal.200" mb={5}>
                <Box as="div" fontSize={42} fontWeight={"700"} lineHeight={"70%"} my={3}>Youla CRM APP</Box>
                {store.isAuth && <Button onClick={() => store.logout()} >Logout</Button>}
            </Flex>
            <Outlet />
        </Container>
    )
}

export default observer(Layout)