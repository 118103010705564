import React, { useEffect, useState } from 'react';
import { Button, VStack, Box, Flex, HStack, Spinner, Tooltip, useDisclosure } from '@chakra-ui/react'
import { useOutletContext } from "react-router-dom"
import AccountsService from '@/services/AccountsService';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const ManagerPanel = () => {

    const [store] = useOutletContext();
    const [loading, setLoading] = useState(false)
    const [accounts, setAccounts] = useState(store.accounts)


    const startParse = async () => {
        setLoading(true)
        try {
            const result = await AccountsService.parse()
            store.setAccounts(result?.data?.result)
            setAccounts(result?.data?.result)
            store.setCount(result?.data?.count)
            alert(result?.data?.message)
        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false)
        }
    }


    return (
        <VStack gap={4} w={"100%"}>
            <Button colorScheme='purple' onClick={() => startParse()} isDisabled={loading}>Проверить сообщения</Button>
            {/* <Box as={"h4"} fontSize={"22px"}>Здесь будет кнопка Проверить сообщения, после ее нажатия происходит парсинг всех аккаунтов в многопоточном режиме и выдача результата на каких аккаунтах есть непрочитанные сообщения.</Box> */}
            <Flex w={"100%"}>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <Box flex={"1"} as="div" height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} className='outline' >Номер телефона аккаунта</Box>
                    <Box flex={"1"} as="div" height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} className='outline'>Количество непрочитанных сообщений</Box>
                    <Box as="div" flex={"1"} height={"100%"} className='outline' display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        Открыть профиль в ADSPOWER
                    </Box>
                </HStack>
            </Flex>
            {loading && <Spinner size={"xl"}/>}
            {
                !loading && accounts && accounts.length > 0 && accounts.map((account, key) => {
                    if (account?.messages > "0")
                        return <Account key={key} account={account} />
                })
            }
        </VStack>
    )
}


const Account = ({ account }) => {


    const openBrowser = async () => {
        await AccountsService.openBrowser(account?.idads)
    }

    return (
        <Flex w={"100%"}>
            <HStack w={"100%"} justifyContent={"space-between"}>
                <Box flex={"1"} as="div" height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} className='outline' >{account?.phone}</Box>
                <Box flex={"1"} as="div" height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"} className='outline'>{account?.messages}</Box>
                <Box as="div" flex={"1"} height={"100%"} className='outline' display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    {/* <Button colorScheme='purple' onClick={openBrowser}>{account?.idads}</Button> */}
                    
                        <CopyToClipboardText text={account?.idads} /> 

                </Box>
            </HStack>
        </Flex>
    )
}

function CopyToClipboardText({ text }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (

        <CopyToClipboard text={text} onCopy={onOpen}>
            <Box>
                <Tooltip label={'ID скопирован в буфер обмена!'} fontSize='md' bg='teal' color="white" placement='top'
                    isOpen={isOpen} onClose={onClose}>
                    <Button colorScheme='purple'>{text}</Button>
                </Tooltip>
            </Box>
        </CopyToClipboard>

    );
}