import React, { useContext } from 'react';
import { observer } from "mobx-react-lite";
import { Context } from "@";
import Layout from '@/Layout'
import {
    Routes,
    Route
} from 'react-router-dom'
import AdminPage from '@/pages/AdminPage';
import LoginPage from '@/pages/LoginPage';
import ManagerPage from '@/pages/ManagerPage'
import ActivatePage from '@/pages/ActivatePage'
import RecoveryPage from '@/pages/RecoveryPage'
import AdminRoute from '@/adminRoute';
import { AdminPanel, ManagersPanel, AccountsPanel, AnswersPanel, ManagerPanel } from '@/components'


function App() {

    const { store } = useContext(Context);

    if (store.isLoading) return <h1>LOADING...</h1>

    return (
        <Routes>
            <Route path="/" element={<Layout/>}>
                <Route path="/" element={<LoginPage />} />
                <Route path="recovery/:activateLink" element={<RecoveryPage />}/>
                <Route path="activate/:activateLink" element={<ActivatePage />}/>
                <Route element={<AdminRoute allowedRoles={["ADMIN"]}/>}>
                    <Route path="/admin" element={<AdminPage />} >
                        <Route index element={<AdminPanel/>}/>
                        <Route path="managers" element={<ManagersPanel/>} />
                        <Route path="answers" element={<AnswersPanel/>} />
                        <Route path="accounts" element={<AccountsPanel/>} />
                    </Route>
                </Route>
                <Route element={<AdminRoute allowedRoles={["USER"]}/>}>
                    <Route path="/manager" element={<ManagerPage />} >
                        <Route index element={<ManagerPanel/>}/>
                        <Route path="accounts" element={<AccountsPanel/>} />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}


export default observer(App);
