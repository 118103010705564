import React, { useEffect, useContext, useState } from "react";
import { Context } from "@";
import AccountsService from "@/services/AccountsService";

export function useAuthAccounts(){

    const { store } = useContext(Context)
    useEffect(() => {
        AccountsService.getAllAccounts(5, 0)
            .then(res => {
                store.setCount(res?.data?.auth)
            })
            .catch(e => console.log(e))
    }, [])
    
}