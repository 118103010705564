import React, { useContext } from "react"
import { Flex, Box, HStack, Button, Stack, Heading, Text, StackDivider } from "@chakra-ui/react"
import { Context } from "@"

export const AdminPanel = () => {

    const { store } = useContext(Context)

    React.useEffect(() => {

    }, [])

    return (
        <Flex w={"100%"}>
            <Stack spacing={10} justify={"space-between"} direction={"row"} w={"100%"}>
                <Box h="200px">
                    {store?.user?.email}
                </Box>
                <Box  h="200px">{store.isAdmin && "ADMIN"}</Box>
                <Feature title="ADMIN PANEL" desc="this admin panel"  h="200px"/>
            </Stack>
        </Flex>
    )
}

function Feature({ title, desc, ...rest }) {
    return (
        <Box p={5} shadow='md' borderWidth='1px' {...rest}>
            <Heading fontSize='xl'>{title}</Heading>
            <Text mt={4}>{desc}</Text>
        </Box>
    )
}