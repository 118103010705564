import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios'
import { Flex, Box, VStack, Heading, Button } from '@chakra-ui/react'
import { observer } from "mobx-react-lite";
import { Context } from '@';

const ActivatePage = () => {

    const navigate = useNavigate();
    const { activateLink } = useParams();
    const [seconds, setSeconds] = useState(5)
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState(null)
    const { store } = useContext(Context)

    useEffect(() => {

        if (success) {
            const timer = setInterval(() => {
                if (seconds > 1) {
                    setSeconds(seconds - 1);
                } else {
                    navigate('/')
                }

            }, 1000);

            return () => clearInterval(timer);
        }

    }, [seconds, success])

    useEffect(() => {
        const activate = async () => {
            
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/activate/${activateLink}`)
            return response
        }

        activate()
            .then(res => {
                setMessage(res?.data?.message)
                store.setUser({
                    ...store.user,
                    email: res?.data?.user_email,
                })
                setSuccess(true)
            })
            .catch(e => {
                setMessage(e.message)
                setSuccess(false)
                navigate('/')
            })
    }, [])

    return (
        <Flex justifyContent={"center"} alignItems={'center'} h={'100vh'} w={'100%'}>
            <Box maxW={"600px"} w="100%" p={5} textAlign={"center"}>
                <VStack>
                    <Heading as="h3" fontSize={'24px'} color={!success && "red"}>{message}</Heading>
                    {success && <Heading as="p" fontSize={"20px"}>Вы будете перенаправлены на страницу авторизации через {seconds} секунд</Heading>}
                </VStack>
            </Box>
        </Flex>
    );
};

export default observer(ActivatePage);