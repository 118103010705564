import React, { useState, useEffect, useContext } from 'react';
import {
    Flex,
    Box,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    IconButton,
    Button,
    Stack,
    FormControl,
    FormLabel,
    Input,
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useDisclosure,
    FormErrorMessage,
    Textarea,
    Spinner,
    Tooltip
} from "@chakra-ui/react"
import { DeleteIcon, EditIcon, CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons'
import AccountsService from '@/services/AccountsService';
import { useFormik } from "formik";
import * as yup from 'yup';
import { AuthButton } from '@/components';
import { useOutletContext } from "react-router-dom"
import { CopyToClipboard } from 'react-copy-to-clipboard';


export const AccountsPanel = () => {

    const [store] = useOutletContext();
    const itemLimit = 5
    const [accounts, setAccounts] = useState([])
    const [offset, setOffset] = useState(0)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [del, setDel] = useState(false)
    const [pagesQuantity, setPagesQuantity] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handlePageChange = (page) => {
        setOffset(page * itemLimit);
    };

    useEffect(() => {
        setLoading(true)
        AccountsService.getAllAccounts(itemLimit, offset)
            .then(res => {
                setAccounts(res?.data?.accounts)
                store.setCount(res?.data?.auth)
                const pagesTotal = Math.ceil(res?.data?.length / itemLimit);
                let array = []
                for (let i = 1; i <= pagesTotal; i++) {
                    array.push(i)
                }
                setPagesQuantity(array)
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })

    }, [del, offset])




    return (
        <Flex w={"100%"}>
            <VStack w={"100%"}>
                <Box>Accounts Panel</Box>
                <Flex justify={"right"} w={"100%"}>
                    <Button type="button" colorScheme="purple" onClick={onOpen}>
                        Добавить аккаунт
                    </Button>
                    <CustomAlertDialog isOpen={isOpen} onClose={onClose} edit={false} loading={isLoading} setLoading={setIsLoading} >
                        {isLoading &&

                            <Spinner
                                m={"auto"}
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl' />

                        }
                        {!isLoading && <AddAccountForm del={del} setDel={setDel} loading={isLoading} setLoading={setIsLoading} onClose={onClose} />}
                    </CustomAlertDialog>
                </Flex>
                <TableContainer w={"100%"} minH={"470px"}>
                    <Table variant='striped' colorScheme='teal'>
                        <TableCaption>Список аккаунтов</TableCaption>
                        <Thead>
                            <Tr>
                                <Th w={"40px"}>ID</Th>
                                <Th textAlign={"right"}>Phone</Th>
                                <Th textAlign={"right"}>ID ADSPOWER</Th>
                                <Th textAlign={"right"}>Proxy</Th>
                                <Th>Auth</Th>
                                <Th w={"220px"} textAlign={"center"}>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody minH={"365px"} w={"100%"}>
                            {/* {
                                loading && <Tr minH={"73px"} w={"100%"}><Td colSpan={5} minH={"73px"}>LOADING...</Td></Tr>
                            } */}
                            {
                                !loading && accounts.length > 0 && accounts.map((account, key) => {
                                    return <Account account={account} del={del} setDel={setDel} key={key} setOffset={setOffset} />
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
                <Stack spacing={4} direction='row' align='right'>
                    {
                        pagesQuantity.length > 1 && pagesQuantity.map((num, key) => {
                            return <Button className={((num - 1) * itemLimit) == offset && `active`} onClick={() => handlePageChange(num - 1)} key={key}>{num}</Button>
                        })
                    }
                </Stack>
            </VStack>
        </Flex>
    );
};


const Account = ({ account, del, setDel, setOffset }) => {

    const [proxy, setProxy] = useState(account?.proxy)
    const [loading, setLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [auth, setAuth] = useState(account?.auth)
    const sleep = ms => new Promise(r => setTimeout(r, ms));
    const { isOpen, onOpen, onClose } = useDisclosure()

    const logout = () => {
        if (window.confirm(`Подтверждаете выход из аккаунта`)) {
            AccountsService.logoutAccount(account?.phone)
                .then(res => {
                    setDel(!del)
                    setOffset(0)
                })
                .catch(e => console.log(e))
                .finally(() => setDel(!del))
        }
    }

    const deleteAccount = () => {
        if (window.confirm(`Подтверждаете удаление аккаунта`)) {
            AccountsService.delete(account?.id)
                .then(res => {
                    setDel(!del)
                    setOffset(0)
                })
                .catch(e => console.log(e))
                .finally(() => setDel(!del))
        }
    }

    const editAccount = () => {
        onOpen()
    }

    const openBrowser = async () => {
        await AccountsService.openBrowser(account?.idads)
    }

    if (loading) return <>
        <Tr minH={"73px"} w={"100%"}>
            <Td colSpan={6}>LOADING...</Td>
        </Tr>
    </>

    return (
        <Tr minH={"73px"} w={"100%"}>
            <Td>
                {account?.id}
            </Td >
            <Td textAlign={"right"}>
                {account?.phone}
            </Td>
            <Td textAlign={"right"}>
                <CopyToClipboardText text={account?.idads}></CopyToClipboardText>
            </Td>
            <Td textAlign={"right"}>
                {proxy}
            </Td>
            <Td>{auth ? <CheckCircleIcon w={8} h={8} color='white.600' /> : <NotAllowedIcon w={8} h={8} color='red.600' />}</Td>
            <Td w={"220px"} textAlign={"right"} >
                {!auth && <AuthButton
                    account={account}
                    del={del}
                    setDel={setDel}
                    setOffset={setOffset}
                />}
                {auth && <Button size={"md"} mr={4}
                    onClick={logout}
                >Выйти</Button>}
                <IconButton colorScheme='blue'
                    aria-label='Edit account'
                    size={"sm"}
                    mr={4}
                    icon={<EditIcon w={4} h={4} color='gray.600' />}
                    onClick={editAccount}
                >
                </IconButton >
                <IconButton colorScheme='blue'
                    aria-label='Delete account'
                    size={"sm"}
                    icon={<DeleteIcon w={4} h={4} color='gray.600' />}
                    onClick={deleteAccount}
                >
                </IconButton >
            </Td>
            <CustomAlertDialog isOpen={isOpen} onClose={onClose} onOpen={onOpen} edit={true} loading={isLoading} setLoading={setIsLoading}>
                {isLoading &&

                    <Spinner
                        m={"auto"}
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl' />

                }
                {!isLoading && <EditAccountForm del={del} setDel={setDel} loading={isLoading} setLoading={setIsLoading} onClose={onClose} account={account} />}
            </CustomAlertDialog>
        </Tr>
    )
}

const CustomAlertDialog = ({ isOpen, onClose, edit, children, loading, setLoading }) => {


    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold' justifyContent={"center"}>
                        {!edit && 'Добавление нового аккаунта'}
                        {edit && 'Редактирование аккаунта'}
                    </AlertDialogHeader>
                    <AlertDialogCloseButton onClick={() => setLoading(false)} />
                    <AlertDialogBody justifyItems={"center"} alignItems={"center"} alignContent={"center"} w={"100%"} display={"flex"}>
                        {children}
                    </AlertDialogBody>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}

const AddAccountForm = ({ setDel, del, loading, setLoading, onClose }) => {


    const [phone, setPhone] = useState("")

    const validationSchema = yup.object({
        phone: yup
            .string()
            .required("Номер телефона обязателен")
        // .min(13, 'Номер телефона не валиден'),
    });

    const formik = useFormik({
        initialValues: {
            phone: "",
            proxy: "",
            cookies: "",
            idads: "",

        },
        onSubmit: (values, { resetForm }) => {
            setLoading(true)
            AccountsService.create(values.phone, values.proxy, values.cookies, values.idads)
                .then(res => {
                    alert(res?.data?.message)
                })
                .catch(e => alert(e?.response?.data?.message))
                .finally(() => {
                    resetForm(); setPhone('')
                    setDel(!del)
                    setLoading(false)
                    onClose()
                })

        },
        validationSchema: validationSchema
    });

    const onChange = (e) => {
        const targetValue = phoneNumberAutoFormat(e.target.value);
        setPhone(targetValue)
        formik.handleChange(e)
    };

    const phoneNumberAutoFormat = (phoneNumber) => {
        const number = phoneNumber.trim().replace(/[^0-9]/g, "");

        if (number.length < 4) return number;
        if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1 $2");
        if (number.length < 9) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1 $2-$3");
        if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{2})(\d{1})/, "$1 $2-$3-$4");
        return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1 $2-$3");
    };

    return (

        <Box bg="transparent" py={6} rounded="md" w={"100%"}>
            <form onSubmit={formik.handleSubmit}>
                <VStack spacing={4} align="flex-end">
                    <FormControl isInvalid={!!formik.errors.phone && formik.touched.phone}>
                        <FormLabel htmlFor="phone" pl={2}>Номер телефона</FormLabel>
                        <Input
                            id="phone"
                            name="phone"
                            type="tel"
                            maxLength={13}
                            variant="filled"
                            onChange={(e) => { onChange(e) }}
                            value={phone}
                            placeholder='900 000-00-00'
                            _placeholder={{ "color": "gray" }}
                        />
                        <FormErrorMessage className='error_wrapper'>{formik.errors.phone}</FormErrorMessage>
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="proxy" pl={2}>Прокси</FormLabel>
                        <Input
                            id="proxy"
                            name="proxy"
                            type="text"
                            variant="filled"
                            placeholder='login:password@host:port'
                            _placeholder={{ "color": "gray" }}
                            onChange={formik.handleChange}
                            value={formik.values.proxy}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="idads" pl={2}>ID профиля ADSPOWER</FormLabel>
                        <Input
                            id="idads"
                            name="idads"
                            type="text"
                            variant="filled"
                            placeholder='id profile adspower'
                            _placeholder={{ "color": "gray" }}
                            onChange={formik.handleChange}
                            value={formik.values.idads}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel htmlFor="cookies" pl={2}>Cookies</FormLabel>
                        <Textarea
                            id="cookies"
                            name="cookies"
                            variant="filled"
                            placeholder='Cookies format JSON'
                            _placeholder={{ "color": "gray" }}
                            onChange={formik.handleChange}
                            value={formik.values.cookies}
                            size='sm'
                            resize={"none"}
                        />
                    </FormControl>

                    <Button type="submit" colorScheme="purple" width="full">
                        Добавить аккаунт
                    </Button>
                </VStack>
            </form>
        </Box>
    );
}


const EditAccountForm = ({ account, setLoading, loading, del, setDel }) => {


    const [proxy, setProxy] = useState(account?.proxy)
    const [idads, setIdads] = useState(account?.idads)


    const submitEditAccount = () => {
        setLoading(true)
        AccountsService.edit(account?.id, proxy, idads)
            .then(res => {
                setProxy(res?.data?.proxy)
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
                setDel(!del)
            })
    }

    return (
        <Flex w={"100%"}>
            <VStack w={"100%"}>
                <FormControl>
                    <FormLabel htmlFor="proxy" pl={2}>Прокси</FormLabel>
                    <Input
                        id="proxy"
                        name="proxy"
                        type="text"
                        variant="filled"
                        placeholder='login:password@host:port'
                        _placeholder={{ "color": "gray" }}
                        onChange={(e) => setProxy(e.target.value)}
                        value={proxy}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="idads" pl={2}>ID профиля ADSPOWER</FormLabel>
                    <Input
                        id="idads"
                        name="idads"
                        type="text"
                        variant="filled"
                        placeholder='id profile adspower'
                        _placeholder={{ "color": "gray" }}
                        onChange={(e) => setIdads(e.target.value)}
                        value={idads}
                    />
                </FormControl>
                <Button mt={4} colorScheme="purple" width="full" type="button" onClick={submitEditAccount} >Подтвердить</Button>
            </VStack>
        </Flex>
    )
}

function CopyToClipboardText({ text }) {

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (

        <CopyToClipboard text={text} onCopy={onOpen}>
            <Box>
                <Tooltip label={'ID скопирован в буфер обмена!'} fontSize='md' bg='teal' color="white" placement='top'
                    isOpen={isOpen} onClose={onClose}>
                    <Button colorScheme='purple'>{text}</Button>
                </Tooltip>
            </Box>
        </CopyToClipboard>

    );
}