import React, {useContext} from "react";
import { CustomMenu } from "@/components"
import { Flex } from '@chakra-ui/react'
import { Outlet } from 'react-router-dom'
import { Context } from "@";
import { observer } from "mobx-react-lite";

const AdminPage = () => {

    const { store } = useContext(Context)
    return (
        <Flex h={"100%"}>
            <CustomMenu count={store.count}/>
            <Outlet context={[ store ]}/>
        </Flex>

    )
}

export default observer(AdminPage)