import React, { useContext } from 'react';
import { Formik, Field } from "formik";
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack
} from "@chakra-ui/react";
import { PasswordInput } from '@/components';
import { Context } from '@';
import * as yup from 'yup';
import axios from 'axios'

export const RecoveryForm = ({activateLink}) => {

    const { store } = useContext(Context)

    const validationSchema = yup.object({
        password: yup
            .string()
            .required('Пароль обязателен')
            .min(5, 'Пароль слишком короткий.'),
        repeat_password: yup
            .string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password')], 'Пароли должны совпадать')
    });

    return (
        <Formik
            initialValues={{
                password: "",
                repeat_password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                store.setMessages({})
                store.recover_password( values.password, activateLink)
                
            }}
        >
            {({ handleSubmit, errors, touched }) => (
                <form onSubmit={(e) => {handleSubmit(); e.preventDefault();}}>
                    <VStack spacing={3} align="flex-start">
                        <FormControl isInvalid={!!errors.password && touched.password}>
                            <FormLabel htmlFor="password">Пароль</FormLabel>
                            <PasswordInput
                                as={Input}
                                id="password"
                                name="password"
                                variant="filled"
                                placeholder='Введите пароль'
                                autoComplete="new-password"
                            />
                            <FormErrorMessage>{errors.password}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.repeat_password && touched.repeat_password}>
                            <FormLabel htmlFor="repeat_password">Повторите пароль</FormLabel>
                            <PasswordInput
                                as={Input}
                                id="repeat_password"
                                name="repeat_password"
                                variant="filled"
                                placeholder='Повторите пароль'
                                autoComplete="new-password"
                            />
                            <FormErrorMessage>{errors.repeat_password}</FormErrorMessage>
                        </FormControl>
                        <Button type="submit" colorScheme="purple" width="full">
                            Обновить пароль
                        </Button>
                    </VStack>
                </form>
            )}
        </Formik>
    );
};
