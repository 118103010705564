import React, { useState, useEffect } from 'react';
import AnswerService from '@/services/AnswerService';
import {
    Flex,
    Box,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    IconButton,
    Button
} from "@chakra-ui/react"
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'

export const AnswersPanel = () => {

    const [answers, setAnswers] = useState([])
    const [loading, setLoading] = useState(false)
    const [del, setDel] = useState(false)


    const createAnswer = () => {
        const result = window.prompt("Введите текст быстрого ответа")
        if (result != null && result != '') {
            setLoading(true)
            AnswerService.create(result).
                then(res => {
                    setLoading(true)
                    AnswerService.getAllAnswers()
                        .then(res => {
                            setAnswers(res?.data)
                        })
                        .catch(e => console.log(e))
                        .finally(() => {
                            setLoading(false)
                        })
                })
                .catch(e => console.log(e))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    useEffect(() => {
        setLoading(true)
        AnswerService.getAllAnswers()
            .then(res => {
                setAnswers(res?.data)
            })
            .catch(e => console.log(e))
            .finally(() => {
                setLoading(false)
            })
    }, [del])

    return (
        <Flex w={"100%"} flex={"flex: 1 0 auto;"}>
            <VStack w={"100%"}>
                <Box>Answers Panel </Box>
                <Flex justify={"flex-end"} w={"100%"}>
                    <Button onClick={createAnswer}>Создать быстрый ответ</Button>
                </Flex>
                <TableContainer w={"100%"}>
                    <Table variant='striped' colorScheme='teal'>
                        <TableCaption>Список быстрых ответов</TableCaption>
                        <Thead>
                            <Tr>
                                <Th w={"40px"}>ID</Th>
                                <Th textAlign={"center"}>TEXT</Th>
                                <Th w={"120px"} textAlign={"center"}>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                loading && <Tr><Td colSpan={3}>LOADING...</Td></Tr>
                            }
                            {
                                !loading && answers.length > 0 && answers.map((answer, key) => {
                                    return <Answer answer={answer} del={del} setDel={setDel} key={key} />
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Flex>
    );
};


const Answer = ({ answer, del, setDel }) => {

    const [text, setText] = useState(answer?.text)
    const [loading, setLoading] = useState(false)

    const deleteAnswer = () => {
        if (window.confirm(`Подтверждаете удаление быстрого ответа`)){
            AnswerService.delete(answer?.text)
            .then(res => {

            })
            .catch(e => console.log(e))
            .finally(() => setDel(!del))
        } 
    }

    const editAnswer = () => {
        const result = window.prompt("Введите новый текст быстрого ответа", text)


        if (result != null && result != '') {
            setLoading(true)
            AnswerService.edit(answer?.id, result)
                .then(res => {
                    setText(res?.data?.text)
                })
                .catch(e => console.log(e))
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    if (loading) return <Tr><Td colSpan={3}>LOADING...</Td></Tr>

    return (
        <Tr>
            <Td >{answer?.id}</Td>
            <Td textAlign={"left"}>{text}</Td>
            <Td w={"120px"} textAlign={"right"}>
                <IconButton colorScheme='blue'
                    aria-label='Delete user'
                    size={"sm"}
                    mr={4}
                    icon={<EditIcon w={4} h={4} color='gray.600' />}
                    onClick={editAnswer}>
                </IconButton >
                <IconButton colorScheme='blue'
                    aria-label='Delete user'
                    size={"sm"}
                    icon={<DeleteIcon w={4} h={4} color='gray.600' />}
                    onClick={deleteAnswer}>
                </IconButton >
            </Td>
        </Tr>
    )
}