import React, { useContext, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "@";
import LoginPage from '@/pages/LoginPage'
import AdminPage from '@/pages/AdminPage';

const AdminRoute = ({ allowedRoles }) => {

    const { store } = useContext(Context)
    const location = useLocation();

    if (store.isLoading) {
        return <div>Checking auth...</div>;
    }

    const uri = allowedRoles[0] === "ADMIN" ? '/admin' : '/manager'

    return (
        allowedRoles[0] === store?.user?.roles.find(role => allowedRoles?.includes(role)) 
            ? <Outlet />
            : store.isAuth ? <Navigate to="/" state={{ from: location }} replace /> : <Navigate to="/" state={{ from: location }} replace />
    );
};

export default observer(AdminRoute);