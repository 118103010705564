import $api from "../http";

export default class AnswerService {
    static async create(text) {
        return $api.post('/create_answer', { text })
    }

    static async edit(id, text) {
        return $api.put('/edit_answer', { id, text })
    }

    static async delete(text) {
        return $api.delete('/delete_answer', { data: { "text": text } }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Правильный тип
            }
        }
        )
    }

    static async getAllAnswers() {
        return $api.get('/get_all_answers')
    }
}