import React, { useContext } from 'react';
import { Formik, Field } from "formik";
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack
} from "@chakra-ui/react";
import { PasswordInput } from '@/components';
import { Context } from '@';
import * as yup from 'yup';
import axios from 'axios'

export const RegisterForm = () => {

    const { store } = useContext(Context)

    const validationSchema = yup.object({
        email: yup
        .string()
        .required("Email обязателен"),
        password: yup
            .string()
            .required('Пароль обязателен')
            .min(5, 'Пароль слишком короткий.'),
        repeat_password: yup
            .string()
            .required('Повторите пароль')
            .oneOf([yup.ref('password')], 'Пароли должны совпадать')
    });

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                repeat_password: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                store.registration(values.email, values.password)
                store.setMessages({})
                // axios.post('http://127.0.0.1:5000/api/v1/register', {email: values.email, password: values.password})
                // .then(response => console.log(response.data))
                
            }}
        >
            {({ handleSubmit, errors, touched }) => (
                <form onSubmit={(e) => {handleSubmit(); e.preventDefault();}}>
                    <VStack spacing={3} align="flex-start">
                        <FormControl isInvalid={!!errors.email && touched.email}>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Field
                                as={Input}
                                id="email"
                                name="email"
                                type="email"
                                variant="filled"
                                placeholder="Введите email"
                                autoComplete="new-email"
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.password && touched.password}>
                            <FormLabel htmlFor="password">Пароль</FormLabel>
                            <PasswordInput
                                as={Input}
                                id="password"
                                name="password"
                                variant="filled"
                                placeholder='Введите пароль'
                                autoComplete="new-password"
                            />
                            <FormErrorMessage>{errors.password}</FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.repeat_password && touched.repeat_password}>
                            <FormLabel htmlFor="repeat_password">Повторите пароль</FormLabel>
                            <PasswordInput
                                as={Input}
                                id="repeat_password"
                                name="repeat_password"
                                variant="filled"
                                placeholder='Повторите пароль'
                                autoComplete="new-password"
                            />
                            <FormErrorMessage>{errors.repeat_password}</FormErrorMessage>
                        </FormControl>
                        <Button type="submit" colorScheme="purple" width="full">
                            Регистрация
                        </Button>
                    </VStack>
                </form>
            )}
        </Formik>
    );
};
