import React, { useState } from 'react'
import { InputGroup, InputRightElement, Button } from '@chakra-ui/react'
import { Field } from 'formik'
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons'

export const PasswordInput = ({...props}) => {

    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)

    return (
        <InputGroup size='md'>
            <Field
                pr='4.5rem'
                type={show ? 'text' : 'password'}
                {...props}
            />
            <InputRightElement width='4.5rem'>
                <Button pe={"0"} h='1.75rem' size='sm' onClick={handleClick} background={"transparent"} _hover={{background: "transparent"}}>
                    {show ? <ViewOffIcon _hover={{transform: "scale(1.2)"}}/> : <ViewIcon _hover={{transform: "scale(1.2)"}}/>}
                </Button>
            </InputRightElement>
        </InputGroup>
    )
}