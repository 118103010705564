import React from 'react';
import {
    Flex,
    Box,
    Heading,
    useDisclosure,
    Link
} from '@chakra-ui/react'
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { RecoveryForm } from '@/components';
import { CustomAlert } from './LoginPage';
import { Context } from '@';


const RecoveryPage = () => {

    const { activateLink } = useParams();
    const { store } = React.useContext(Context)
    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: true })

    return (
        <Flex align="center" justify="center" h="100vh">
            <Box bg="gray.700" p={6} rounded="md" w={600} >
                <Heading as={"h5"} fontSize={"20px"} textAlign={"center"} fontWeight={"600"} mb={3}>Обновить пароль</Heading>
                <RecoveryForm activateLink={activateLink} />
                <Box>
                    <Link href="/">Войти</Link>
                </Box>
                <Box mt={3}>
                    {store?.messages?.login?.error?.message && <CustomAlert type="error" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.login?.error?.message}</CustomAlert>}
                    {store?.messages?.login?.success?.message && <CustomAlert type="success" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.login?.success?.message}</CustomAlert>}
                </Box>
            </Box>
        </Flex>
    )
}

export default observer(RecoveryPage)