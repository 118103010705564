import $api from "../http";
import { $browser } from "../http";

export default class AccountsService {
    static async create(phone, proxy, cookies, idads) {
        return $api.post('/create_account', { phone, proxy, cookies, idads })
    }

    static async edit(id, proxy, idads) {
        return $api.put('/edit_account', { id, proxy, idads })
    }

    static async delete(id) {
        return $api.delete('/delete_account', { data: { "id": id } }, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded', // Правильный тип
            }
        }
        )
    }

    static async getAllAccounts(count, offset) {
        return $api.get(`/get_all_accounts?count=${count}&offset=${offset}`)
    }

    static async authAccount(id, phone, proxy, auth){
        return $api.post(`/auth_account`, { id, phone, auth })
    }

    static async logoutAccount(phone){
        return $api.post('/logout_account', { phone })
    }

    static async authAccountYoula(phone, proxy){
        return $api.get(`/auth_account_youla?phone=${phone}&proxy=${proxy}`)
    }

    static async sendCookiesYoula(cookies){
        return $api.post(`/send_cookies_youla`, { cookies })
    }

    static async sendCaptchaYoula(code){
        return $api.get(`/send_captcha_youla?code=${code}`)
    }

    static async parse(){
        return $api.get('/parse')
    }

    static async openBrowser(idads){
        return $browser.get(`/browser/start?user_id=${idads}`)
    }
}