import $api from "../http";

export default class AuthService {
    static async login(email, password) {
        return $api.post('/login', {email, password})
    }

    static async registration(email, password) {
        return $api.post('/registration', {email, password})
    }

    static async logout() {
        return $api.post('/logout')
    }

    static async checkAuth(token) {
        return $api.get(`/check_auth?token=${token}`)
    }

    static async checkAdmin() {
        return $api.get('/check_admin')
    }

    static async recover_password_email(email) {
        return $api.post('/recover_password_email', {email})
    }

    static async recover_password(password, link) {
        return $api.post('/recover_password', {password, link})
    }

    static async getAllUsers() {
        return $api.get('/get_all_users')
    }

    static async deleteUser(email) {
        return $api.delete('/delete_user', {data: {"email": email}})
    }
}