import {
    Box,
    VStack,
    List,
    ListItem,
    Badge,
    Text
} from '@chakra-ui/react'
import { Link, NavLink } from 'react-router-dom'
import { useAuthAccounts } from '@/hooks/useAuthAccounts'

export const CustomMenu = ({ admin = true, count }) => {

    useAuthAccounts()

    if (admin) {
        return (
            <Box w={"280px"}>
                <VStack alignItems={"start"} >
                    <List spacing={3}>
                        <ListItem>
                            <NavLink end to={"/admin"}>
                                Dashboard
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink to={"/admin/managers"}>
                                Менеджеры
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink to={"/admin/answers"}>
                                Быстрые ответы
                            </NavLink>
                        </ListItem>
                        <ListItem>
                            <NavLink to={"/admin/accounts"}>
                                <Text position={"relative"}>
                                    <Badge variant='outline' px={2} fontSize='12px' colorScheme='red' borderRadius={25} position={"absolute"} right={"-20px"} top={"-5px"}>
                                        {count}
                                    </Badge>
                                    Аккаунты Юла
                                </Text>
                            </NavLink>
                        </ListItem>
                    </List>
                </VStack>
            </Box>
        )
    }

    return (
        <Box w={"280px"}>
            <VStack alignItems={"start"} >
                <List spacing={3}>
                    <ListItem>
                        <NavLink end to={"/manager"}>
                            Dashboard
                        </NavLink>
                    </ListItem>
                    <ListItem>
                        <NavLink to={"/manager/accounts"}>
                            <Text position={"relative"}>
                                <Badge variant='outline' px={2} fontSize='12px' colorScheme='red' borderRadius={25} position={"absolute"} right={"-20px"} top={"-5px"}>
                                    {count}
                                </Badge>
                                Аккаунты Юла
                            </Text>

                        </NavLink>
                    </ListItem>
                </List>
            </VStack>
        </Box>
    )
}