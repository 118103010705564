import React, { useContext, useState } from 'react';
import { Formik, Field } from "formik";
import {
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    VStack,
    Box,
    Alert
} from "@chakra-ui/react";
import { PasswordInput } from '@/components';
import { Context } from '@';
import * as yup from 'yup';

export const LoginForm = () => {


    const [recovery, setRecovery] = useState(false)

    const validationSchema = yup.object({
        email: yup
            .string()
            .required("Email обязателен"),
        password: yup
            .string()
            .required('Пароль обязателен')
    });

    const { store } = useContext(Context)

    return (
        <>
            {!recovery ? (<Formik
                initialValues={{
                    email: store.user.email,
                    password: ""
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    store.setMessages({})
                    store.login(values.email, values.password)
                }}
            >
                {({ handleSubmit, errors, touched }) => (
                    <form onSubmit={handleSubmit}>
                        <VStack spacing={3} align="flex-start" minH={"220px"}>
                            <FormControl isInvalid={!!errors.email && touched.email}>
                                <FormLabel htmlFor="login_email">Email</FormLabel>
                                <Field
                                    as={Input}
                                    id="login_email"
                                    name="email"
                                    type="email"
                                    variant="filled"
                                    placeholder="Введите email"
                                    autoComplete="email"
                                />
                                <FormErrorMessage>{errors.email}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors.password && touched.password}>
                                <FormLabel htmlFor="login_password">Пароль</FormLabel>
                                <PasswordInput
                                    as={Input}
                                    placeholder='Введите пароль'
                                    id="login_password"
                                    name="password"
                                    autoComplete="current-password"
                                    variant="filled"
                                />
                                <FormErrorMessage>{errors.password}</FormErrorMessage>
                            </FormControl>
                            <Button type="submit" colorScheme="purple" width="full">
                                Войти
                            </Button>
                        </VStack>
                    </form>
                )}
            </Formik>) : (
                <RecoveryForm />
            )}
            <Box as="div" my={5} pt={0}>
                <Button colorScheme='teal' variant='link' onClick={() => setRecovery(!recovery)}>{!recovery ? 'Восстановить пароль' : 'Войти в систему'}
                </Button>
            </Box>
        </>
    );
}


const RecoveryForm = () => {


    const validationSchema = yup.object({
        email: yup
            .string()
            .required("Email обязателен")
    });

    const { store } = useContext(Context)

    return (
        <Formik
            initialValues={{
                email: store.user.email,
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                store.setMessages({})
                store.recover_password_email(values.email)
            }}
        >
            {({ handleSubmit, errors, touched }) => (
                <form onSubmit={handleSubmit} >
                    <VStack spacing={3} align="flex-start" minH={"220px"}>
                        <FormControl isInvalid={!!errors.email && touched.email}>
                            <FormLabel htmlFor="login_email">Email</FormLabel>
                            <Field
                                as={Input}
                                id="login_email"
                                name="email"
                                type="email"
                                variant="filled"
                                placeholder="Введите email"
                                autoComplete="email"
                            />
                            <FormErrorMessage>{errors.email}</FormErrorMessage>
                        </FormControl>
                        <Button type="submit" colorScheme="purple" width="full">
                            Сменить пароль
                        </Button>
                    </VStack>
                </form>
            )}
        </Formik>
    );
}



