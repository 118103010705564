import React, { useContext, useState } from 'react';
import { Flex } from '@chakra-ui/react'
import { Context } from "@";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from 'react-router-dom'
import { CustomMenu } from '@/components'

const ManagerPage = () => {

    const { store } = useContext(Context)

    if(store.isAdmin)
        <Navigate to="/admin" />
        
    return (
        <Flex maxW={"full"}>
            <CustomMenu admin={false} count={store.count}/>
            <Outlet context={[ store ]}/>
        </Flex>
    );
};

export default observer(ManagerPage);