import React, { useContext } from 'react';
import { RegisterForm, LoginForm } from '@/components';
import {
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Flex,
    Box,
    Alert,
    AlertIcon,
    AlertDescription,
    useDisclosure,
    CloseButton, Button, VStack
} from '@chakra-ui/react'
import { observer } from "mobx-react-lite";
import { Context } from '@';
import { Navigate } from 'react-router-dom'

const LoginPage = () => {

    const { store } = useContext(Context)
    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure({ defaultIsOpen: true })


    if(store.isAuth && store.isAdmin)
        return <Navigate to="/admin" />

    if(store.isAuth && !store.isAdmin)
        return <Navigate to="/manager" />

    return (
        <Flex align="center" justify="center" h="100vh">
            <Box bg="gray.700" p={6} rounded="md" w={600} minH={500}>
                <Tabs colorScheme="teal">
                    <TabList>

                        <Tab>Вход</Tab>
                        {/* {!store.isAdmin &&   */}
                            <Tab>Регистрация</Tab>
                        {/* } */}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <LoginForm />
                            <Box mt={3}>
                                {store?.messages?.login?.error?.message && <CustomAlert type="error" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.login?.error?.message}</CustomAlert>}
                                {store?.messages?.login?.success?.message && <CustomAlert type="success" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.login?.success?.message}</CustomAlert>}
                            </Box>

                        </TabPanel>
                        {
                            // !store.isAdmin &&
                            <TabPanel>
                                <RegisterForm />
                                <Box mt={3}>
                                    {store?.messages?.register?.error?.message && <CustomAlert type="error" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.register?.error?.message}</CustomAlert>}
                                    {store?.messages?.register?.success?.message && <CustomAlert type="success" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>{store?.messages?.register?.success?.message}</CustomAlert>}
                                </Box>
                            </TabPanel>
                        }
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>

    );
};

export function CustomAlert({ children, onClose, isOpen, onOpen, type }) {

    const { store } = useContext(Context)

    const close = () => {
        onClose()
        store.setMessages({})
        onOpen()
    }

    if (isOpen) return (
        <Box>
            <Alert status={type} variant='solid' borderRadius={6}>
                <AlertIcon />
                <Box w={"100%"}>
                    <AlertDescription>{children}</AlertDescription>
                </Box>
                <CloseButton
                    alignSelf='flex-end'
                    position='relative'
                    right={-1}
                    top={-1}
                    onClick={close}
                />
            </Alert>
        </Box>
    )

    return <></>
}

export default observer(LoginPage);