import axios from 'axios';

const PY_API_URL = process.env.REACT_APP_API_URL

const $api = axios.create({
    baseURL: `${PY_API_URL}/api/v1`,
})

export const $browser = axios.create({
    baseURL: `http://local.adspower.net:50325/api/v1`,
})

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})

export default $api;