import React, { useEffect, useState } from 'react';
import {
    Flex,
    Box,
    VStack,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    IconButton
} from "@chakra-ui/react"
import { DeleteIcon } from '@chakra-ui/icons'
import AuthService from '@/services/AuthService';

export const ManagersPanel = () => {

    const [users, setUsers] = useState([])
    const [del, setDel] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        AuthService.getAllUsers()
            .then(res => setUsers(res?.data))
            .catch((e) => console.log("Error", e))
            .finally(() => setLoading(false))
    }, [del])

    return (
        <Flex w={"100%"} flex={"flex: 1 0 auto;"}>
            <VStack w={"100%"}>
                <Box>Managers Panel</Box>
                <TableContainer w={"100%"}>
                    <Table variant='striped' colorScheme='teal'>
                        <TableCaption>Список зарегистрированных менеджеров</TableCaption>
                        <Thead>
                            <Tr>
                                <Th >ID</Th>
                                <Th>EMAIL</Th>
                                <Th textAlign={"center"}>Active</Th>
                                <Th textAlign={"right"}>Action</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                loading && <Tr><Td colSpan={4}>LOADING...</Td></Tr>
                            }
                            {
                                !loading && users.length > 0 && users.map((user, key) => {
                                    return <UserItem user={user} key={key} setDel={setDel} del={del} />
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </Flex>
    );
}

const UserItem = ({ user, setDel, del }) => {

    const deleteUser = () => {
        if (window.confirm(`Подтверждаете удаление менеджера ${user?.email}`)){
            AuthService.deleteUser(user?.email)
            setDel(!del)
        }
    }

    return (
        <Tr>
            <Td ># {user?.id}</Td>
            <Td>{user?.email}</Td>
            <Td textAlign={"center"}>{user?.isActivated ? "Да" : "Нет"}</Td>
            <Td textAlign={"right"}><IconButton colorScheme='blue'
                aria-label='Delete user'
                size={"sm"}
                icon={<DeleteIcon w={4} h={4} color='gray.600' />}
                onClick={deleteUser}>
            </IconButton >
            </Td>
        </Tr>
    )
}