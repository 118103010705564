import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ChakraProvider } from '@chakra-ui/react'
import Store from './store/store';
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";
// import { ContextSocketProvider } from '@/context/context-socketio'



const store = new Store()

export const Context = createContext({
    store
})

const setColorMode = () => {
    if (!localStorage.getItem("chakra-ui-color-mode-default")) {
        localStorage.setItem("chakra-ui-color-mode", "dark")
        localStorage.setItem("chakra-ui-color-mode-default", "set")
    }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        {setColorMode()}
        <BrowserRouter>
            <ChakraProvider>
                <Context.Provider value={{ store }}>
                    <Routes>

                        <Route path="/*" element={<App />} />
                    </Routes>
                </Context.Provider>
            </ChakraProvider>
        </BrowserRouter>
    </>
);

