import React, { useState, useEffect, useContext } from "react"
import SocketContext from '@/context/context-socketio';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Input,
    Box,
    Button,
    useDisclosure,
    Flex,
    VStack,
    Spinner,
    FormControl,
    FormLabel, 
    Textarea
} from '@chakra-ui/react'
import AccountsService from "../../services/AccountsService";

async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const AuthButton = ({ account, del, setDel }) => {

    const [loading, setLoading] = useState(false)
    const { isOpen, onOpen, onClose } = useDisclosure()

    const login = async (e) => {
        onOpen()
    }

    return (
        <>
            <Button
                size={"md"}
                mr={4}
                onClick={login}
            >
                Авторизоваться
            </Button>
            <CustomAlertDialog
                account={account}
                isOpen={isOpen}
                onClose={onClose}
                loading={loading}
                setLoading={setLoading}
                del={del}
                setDel={setDel}
            />
        </>

    )
}

const CustomAlertDialog = ({ account, isOpen, onClose, loading, setLoading, del, setDel }) => {

    const cancelRef = React.useRef()
    const [cookies, setCookies] = useState(account?.cookies)

    const handleChange = (event) => {
        setCookies(event.target.value)
    }


    const sendCode = async () => {
        setLoading(true)
        AccountsService.create(account?.phone, account?.proxy, cookies, account?.idads)
            .then(res => {
                if (res?.data?.result) {
                    alert(res?.data?.message)
                    console.log(res?.data)
                }
            })
            .catch(e => {
                console.log(e)
            })
            .finally(async res => {
                setLoading(false)
                onClose()
                setDel(!del)
            })

    }

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Инфо о процессе авторизации
                    </AlertDialogHeader>
                    <AlertDialogBody>
                        <form>
                    <FormControl>
                        <FormLabel htmlFor="cookies" pl={2}>Cookies</FormLabel>
                        <Textarea
                            id="cookies"
                            name="cookies"
                            variant="filled"
                            placeholder='Cookies format JSON'
                            _placeholder={{ "color": "gray" }}
                            onChange={handleChange}
                            value={cookies}
                            size='sm'
                            resize={"none"}
                            isDisabled={loading}
                        />
                    </FormControl>
                    </form>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <VStack w={"100%"}>
                            <Flex justify={"stretch"} w={"100%"} alignItems={"center"}>
                                {<Button
                                    display={"flex"}
                                    isLoading={loading}
                                    loadingText='Ждем ответ...'
                                    w={"100%"}
                                    flex={"1"}
                                    onClick={sendCode}
                                >
                                    Отправить
                                </Button>
                                }
                                <Button display={"flex"} flex={"1"} w={"100%"} ref={cancelRef} isDisabled={loading} colorScheme='red' onClick={onClose} ml={2}>
                                    Отмена
                                </Button>
                            </Flex>
                        </VStack>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}